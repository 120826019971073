@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900");
.sidebarWidth {
  width: 20%;
}

.mainWidth {
  width: 80%;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.container-fluid {
  padding: 0;
}

.full-height {
  height: 100vh;
}

.full-height-fixed {
  height: calc(100vh - 56px);
  margin-top: 56px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-right: 0;
  margin-left: 0;
}

.full-height-fixed > * {
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.clickable {
  cursor: pointer;
}

.text-white a {
  color: #fff;
}

.bg-dark {
  background-color: #292b2c !important;
}

.badge-secondary {
  background-color: #563d7c;
}

.sortable {
  cursor: pointer;
}

.st-sort-ascent:before {
  content: '\25B2';
}

.st-sort-descent:before {
  content: '\25BC';
}

.sheetTable tr td {
  padding: 10px;
}

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

/**
* Scrollbar
 */
*::-webkit-scrollbar {
  width: .5em;
}

*::-webkit-scrollbar-thumb {
  background-color: #292b2c;
  border-radius: 5px;
  outline: 1px solid slategrey;
}

body {
  background-color: #eee;
}

button,
a {
  cursor: pointer;
  border-radius: 5px !important;
}

button:active, button:focus, button:hover,
a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
}

.list-group-item.active a {
  color: #fff;
}

textarea,
input {
  border-radius: 0 !important;
}

textarea:active, textarea:focus, textarea:hover,
input:active,
input:focus,
input:hover {
  outline: none;
}

.card,
.input-group-addon {
  border-radius: 0;
}

button:hover, button:active, button:focus {
  box-shadow: none !important;
}

.input-group-addon {
  background-color: #C084A4;
  color: #fff;
}

.has-danger {
  color: #d9534f;
}

.has-danger .input-group-addon {
  background-color: #d9534f;
  color: #fff;
}

.has-danger input {
  border-color: #d9534f;
}

.has-danger input:focus {
  border-color: #d9534f;
}

.has-danger textarea {
  border-color: #d9534f;
}

.has-danger textarea:focus {
  border-color: #d9534f;
}

table {
  margin: 0rem;
}

th input,
th .select2-container {
  max-width: 150px;
}

.has-danger .select2-choice {
  border: 1px solid #d9534f !important;
}

.has-danger .select2-choice .select2-arrow {
  background-color: transparent !important;
}

ul.pagination li.pagination-item.active a {
  color: #fff;
}

ul.pagination li.pagination-item.active a:hover {
  color: #fff;
}

.ui-draggable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

.ui-draggable-dragging {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #292b2c;
  color: #fff;
  clear: both;
  float: left;
  width: 150px;
}

.ui-droppable-hover {
  background-color: #c96198 !important;
  color: #fff;
}

.ui-droppable-hover a {
  color: #fff;
}

.ui-notification {
  box-shadow: none;
  padding: 10px 20px;
  width: 480px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-notification h3 {
  font-size: 1.2em;
}

.ui-notification.warning {
  background-color: #f0ad4e;
}

.ui-notification:hover {
  opacity: 1 !important;
}

.ngdialog-content {
  border-radius: 0 !important;
  padding: 25px !important;
}

.ngdialog-content h3 {
  color: #C084A4;
  margin: 10px 0 20px 0;
}

.ngdialog-content p {
  font-size: 16px;
}

.ngdialog-content .container {
  padding: 0;
}

.ngdialog-footer {
  text-align: right;
}

.navbar {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.navbar .navbar-brand .toggleSidebar {
  display: inline-block;
  color: #fff !important;
  margin-right: 10px;
  transition: all ease 500ms;
}

.navbar .navbar-brand .toggleSidebar.toggled {
  transform: rotate(90deg);
  transition: all ease 500ms;
}

.navbar .navbar-brand img {
  height: 25px;
}

.navbar .dropdown-menu.toggle-right {
  right: 0;
  left: auto;
  top: 45px;
  border: none;
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card {
  border-radius: 0;
}

.card .half-left {
  padding: 5px;
  float: left;
  display: inline-block;
  width: 49%;
}

.card .half-right {
  padding: 5px;
  float: right;
  display: inline-block;
  width: 49%;
}

.card.fullScreen {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.card .card-header {
  border-radius: 0;
  color: #fff;
  background-color: #375f9b;
}

.card .card-header .mb-0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.card .card-body {
  padding: 10px;
  margin: 10px;
}

.card .card-block {
  padding: 0;
}

.card .card-block.padding {
  padding: 10px !important;
}

.card .card-block.padding .list-inline {
  margin: 0;
}

.card .card-block .list-group {
  border: none;
}

.card .card-block .list-group .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card .card-block .list-group .list-group-item:first-of-type {
  border-top: none;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #C084A4;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
}

.custom-radio input:checked ~ .checkmark {
  background-color: #C084A4;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}

.custom-radio .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  content: "";
  position: absolute;
  display: none;
}

.custom-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

.select2-container {
  margin: 0;
  position: relative;
  display: block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  box-sizing: border-box;
}

.select2-drop {
  box-sizing: border-box;
}

.select2-search {
  box-sizing: border-box;
}

.select2-search input {
  box-sizing: border-box;
}

.select2-container .select2-choice {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: normal;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 10px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  background: url("app/assets/images/select2.png") right top no-repeat;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-active {
  border-top: none;
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  background: url("app/assets/images/select2.png") no-repeat 0 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}

.select2-search input {
  width: 100% !important;
  height: auto !important;
  min-width: 100%;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  background: #fff url("app/assets/images/select2.png") no-repeat 100% -22px;
  background: url("app/assets/images/select2.png") no-repeat 100% -22px, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
  width: 100% !important;
}

.select2-search input.select2-active {
  background: #fff url("app/assets/images/select2-spinner.gif") no-repeat 100%;
  background: url("app/assets/images/select2-spinner.gif") no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  box-shadow: 0 1px 0 #fff inset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-dropdown-open.select2-drop-above .select2-choice, .select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #C084A4;
  border-top-color: transparent;
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  font-weight: normal;
  font-size: 14px;
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results .select2-highlighted {
  background: #C084A4;
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results, .select2-results .select2-searching, .select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url("app/assets/images/select2-spinner.gif") no-repeat 100%;
}

/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}

/* multiselect */
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #ccc;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #C084A4;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url("app/assets/images/select2-spinner.gif") no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;
  line-height: 13px;
  color: #333;
  cursor: default;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e4e4e4;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: none;
  background: url("app/assets/images/select2.png") right top no-repeat;
}

.select2-container-multi .select2-search-choice-close {
  left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover, .select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none;
}

/* disabled styles */
/* end multiselect */
.select2-result-selectable .select2-match, .select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input, .select2-search-choice-close {
    background-image: url("app/assets/images/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {
    background-image: url("app/assets/images/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-search input {
    background-position: 100% -21px !important;
  }
}

.select2-chosen button {
  background-color: transparent !important;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle;
}

.switch input {
  display: none;
}

.switch-text {
  line-height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.btn-danger,
.btn-success,
.btn-info,
.btn-primary {
  color: #fff !important;
}

.btn-sign {
  background-color: #C084A4;
  color: #fff;
}

.btn-comment {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex: 1;
      flex: 1;
  color: #fff;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  background-color: #C084A4;
}

.ui-calendar .controls h5 {
  display: inline-block;
  min-width: 150px;
  text-align: center;
  text-transform: capitalize;
}

.ui-calendar .controls .btn-default {
  background-color: transparent;
  color: #aaa;
}

.ui-calendar table.day-view thead tr th,
.ui-calendar table.month-view thead tr th,
.ui-calendar table.week-view thead tr th {
  text-transform: capitalize;
  text-align: center;
  vertical-align: middle;
  background-color: #C084A4;
  color: #fff;
}

.ui-calendar table.day-view thead tr th h5,
.ui-calendar table.month-view thead tr th h5,
.ui-calendar table.week-view thead tr th h5 {
  margin: 0;
}

.ui-calendar table.day-view thead tr th .btn,
.ui-calendar table.month-view thead tr th .btn,
.ui-calendar table.week-view thead tr th .btn {
  background-color: transparent;
  font-size: 1.5em;
  color: #fff;
}

.ui-calendar table.month-view tbody tr td {
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.2em;
  height: 100px;
  width: calc(100%/7);
}

.ui-calendar table.month-view tbody tr td.current-month {
  color: rgba(0, 0, 0, 0.7);
}

.ui-calendar table.month-view tbody tr td.today {
  color: #C084A4;
}

.ui-calendar table.month-view tbody tr td.hasEvent {
  padding-left: 0;
  padding-right: 0;
}

.ui-calendar table.month-view tbody tr td.hasEvent.isEventStart span.evt {
  position: relative;
  border-left: 2px solid #2c1b38;
}

.ui-calendar table.month-view tbody tr td.hasEvent.isEventStart span.evt small {
  display: block;
  position: absolute;
  white-space: nowrap;
  color: #fff;
  left: 10px;
  top: 0;
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.ui-calendar table.month-view tbody tr td.hasEvent.isEventEnd span.evt {
  border-right: 2px solid #2c1b38;
}

.ui-calendar table.month-view tbody tr td.hasEvent span.evt {
  display: block;
  width: 100%;
  height: 25px;
  background-color: #613d7c;
  margin-top: 10px;
}

.ui-calendar table.day-view thead tr th {
  text-align: center;
}

.ui-calendar table.day-view tbody tr th {
  text-align: center;
  width: calc(100%/8);
}

.ui-calendar table.day-view tbody tr td {
  text-align: center;
  vertical-align: top;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
}

.ui-calendar table.day-view tbody tr td.has-event {
  border: none;
}

.ui-calendar table.day-view tbody tr td.has-event span.evt {
  position: relative;
  display: block;
  height: 100%;
  width: 50px;
  background-color: #613d7c;
  margin: auto;
}

.ui-calendar table.day-view tbody tr td.has-event span.evt small {
  font-size: 1em;
  display: block;
  position: absolute;
  white-space: nowrap;
  color: #fff;
  left: 35px;
  top: 10px;
  transform-origin: 0 0;
  transform: rotate(90deg);
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 99;
}

.ui-calendar table.day-view tbody tr td.isEventStart span.evt {
  border-top: 2px solid #2c1b38;
}

.ui-calendar table.day-view tbody tr td.isEventEnd span.evt {
  border-bottom: 2px solid #2c1b38;
}

.ui-calendar table.week-view tbody tr th {
  text-align: center;
}

.ui-calendar table.week-view tbody tr td {
  text-align: center;
  vertical-align: top;
  width: calc(100%/8);
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
}

.ui-calendar table.week-view tbody tr td.has-event {
  border: none;
}

.ui-calendar table.week-view tbody tr td.has-event span.evt {
  position: relative;
  display: block;
  height: 100%;
  width: 25px;
  background-color: #613d7c;
  margin: auto;
}

.ui-calendar table.week-view tbody tr td.has-event span.evt small {
  display: block;
  position: absolute;
  white-space: nowrap;
  color: #fff;
  left: 23px;
  top: 10px;
  transform-origin: 0 0;
  transform: rotate(90deg);
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 99;
}

.editable {
  color: #C084A4 !important;
  font-size: 14px;
  white-space: nowrap;
}

.badge-info .editable {
  color: #fff !important;
}

.editable-buttons button {
  background-color: #C084A4;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 14px;
}

.editable-buttons button:last-of-type {
  background-color: #d9534f;
}

.editable-controls select {
  padding: 3px;
  border: none;
}

.fc-content {
  color: #fff;
}

.fc-center h2 {
  text-transform: capitalize;
}

.fc-day-header {
  background-color: #C084A4;
  color: #fff;
  text-transform: capitalize;
}

.fc-button {
  border: 1px solid #C084A4;
  text-shadow: none;
  background-color: #C084A4;
  color: #fff;
  font-size: 1em;
}

.fc-button.fc-state-default {
  background-image: none;
  background-color: #C084A4;
  color: #fff;
}

.fc-button.fc-state-active {
  background-color: #af628b;
  color: #fff;
}

.fc-button.fc-state-hover {
  background-color: #b77398;
  color: #fff;
}

.udatetime {
  position: relative;
  padding: 0 !important;
}

.udatetime table.cal {
  position: absolute;
  z-index: 9999;
}

.udatetime td.day button {
  cursor: pointer;
}

.udatetime td.day button.btn-default {
  background-color: transparent;
}

.udatetime table.table.cal {
  display: none !important;
  transition: all ease 2s;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  width: auto !important;
}

.udatetime table.cal.shown {
  display: table !important;
  transition: all ease 2s;
}

.udatetime .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.udatetime .input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-addon:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn, .udatetime .input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-btn:not(:last-child) > .btn, .udatetime .input-group-btn:not(:last-child) > .btn-group > .btn, .udatetime .input-group-btn:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group .form-control, .udatetime .input-group-addon, .udatetime .input-group-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.udatetime .input-group .form-control {
  position: relative;
  z-index: 2;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.udatetime .form-control {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin: 0 !important;
}

.udatetime .form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.udatetime .input-group .form-control:not(:first-child), .udatetime .input-group-addon:not(:first-child) {
  margin-left: -17px;
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
  z-index: 2;
}

.udatetime .input-group-btn:not(:first-child) > .btn, .udatetime .input-group-btn:not(:first-child) > .btn-group > .btn, .udatetime .input-group-btn:not(:first-child) > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.udatetime .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn, .udatetime .input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.udatetime .input-group-addon {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #fafafa;
  text-align: center;
  background-color: #C084A4;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  white-space: nowrap;
  vertical-align: middle;
}

.udatetime .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.udatetime .input-group .form-control, .udatetime .input-group-addon, .udatetime .input-group-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.udatetime fieldset.form-group {
  margin-bottom: 1rem;
}

.udatetime .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.udatetime .table thead th:first-of-type {
  width: auto !important;
}

.udatetime .table thead th:last-of-type {
  width: auto !important;
}

.udatetime .table thead th:last-child {
  min-width: auto;
}

.udatetime .table tr:hover {
  background-color: transparent;
}

.udatetime .table tr td {
  border: none !important;
  text-align: center;
  width: auto !important;
}

.udatetime .table tr td .btn {
  height: 30px;
  width: 30px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center !important;
  display: inline-block !important;
  margin: auto;
  padding: 0 !important;
  line-height: inherit;
  border-radius: 50% !important;
}

.udatetime .table tr td .btn.month {
  height: 50px;
  width: 50px;
  text-transform: capitalize;
}

.udatetime .table tr td .btn.btn-default {
  border: none;
}

.udatetime .table tr td .btn.btn-block {
  width: 100%;
  padding: 10px;
}

.udatetime .table tfoot td .btn {
  border-radius: 0;
  height: auto;
  width: auto;
}

.udatetime .table tfoot td .btn.btn-info {
  background-color: transparent;
  color: #af628b !important;
  border: none;
  font-size: 1.2em;
}

.udatetime table {
  border-collapse: collapse;
  background-color: transparent;
}

.udatetime .table thead th {
  vertical-align: bottom;
  text-transform: capitalize;
  border: none;
}

.udatetime .table thead th button {
  background-color: transparent;
  color: #eee;
  font-size: 1.5em;
}

.udatetime thead tr:first-of-type th {
  background-color: #af628b;
}

.udatetime thead tr:last-of-type {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.udatetime .thead-inverse th {
  color: #fff;
  background-color: #C084A4;
}

.udatetime .table td, .udatetime .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #eee;
}

.udatetime .text-center {
  text-align: center !important;
}

.udatetime .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .5rem 1rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
}

.udatetime .btn-group-sm > .btn, .udatetime .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.udatetime button, .udatetime input, .udatetime select, .udatetime textarea {
  line-height: inherit;
}

.udatetime [role=button], .udatetime a, .udatetime area, .udatetime button, .udatetime input, .udatetime label, .udatetime select, .udatetime summary, .udatetime textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.udatetime [type=reset], .udatetime [type=submit], .udatetime button, .udatetime html [type=button] {
  -webkit-appearance: button;
}

.udatetime button, .udatetime input {
  overflow: visible;
}

.udatetime button, .udatetime select {
  text-transform: none;
}

.udatetime .btn-block {
  display: block;
  width: 100%;
}

.udatetime .btn-info {
  color: #fff;
  background-color: #C084A4;
  border-color: #C084A4;
}

.udatetime .btn-success {
  color: #fff;
  background-color: #c96198;
  border-color: #c96198;
}

.udatetime .btn-primary {
  color: #fff;
  background-color: #C084A4;
  border-color: #C084A4;
}

.udatetime .h3, .udatetime h3 {
  font-size: 1.75rem;
}

.udatetime .h1, .udatetime .h2, .udatetime .h3, .udatetime .h4, .udatetime .h5, .udatetime .h6, .udatetime h1, .udatetime h2, .udatetime h3, .udatetime h4, .udatetime h5, .udatetime h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.udatetime input:-moz-read-only {
  background-color: #ccc;
  cursor: not-allowed;
}

.udatetime input:read-only {
  background-color: #ccc;
  cursor: not-allowed;
}

.udatetime input:-moz-read-only + span.input-group-addon {
  background-color: #ccc;
}

.udatetime input:read-only + span.input-group-addon {
  background-color: #ccc;
}

.udatetime input.form-control {
  width: auto !important;
  max-width: 160px;
  margin-left: 10px;
}

.udatetime input.time-input {
  font-size: 1.8em;
  border: none;
  width: 80px !important;
  text-align: center;
}

.udatetime input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #FFFFFF;
}

.blog-comment::before,
.blog-comment::after,
.blog-comment-form::before,
.blog-comment-form::after {
  content: "";
  display: table;
  clear: both;
}

.blog-comment {
  padding-left: 15px;
  padding-right: 15px;
}

.blog-comment ul {
  list-style-type: none;
  padding: 0;
}

.blog-comment img {
  opacity: 1;
  filter: Alpha(opacity=100);
  border-radius: 4px;
}

.blog-comment img.avatar {
  position: relative;
  float: left;
  margin-left: 0;
  margin-top: 0;
  width: 65px;
  height: 65px;
}

.blog-comment .post-comments {
  border: 1px solid #eee;
  margin-bottom: 20px;
  margin-left: 85px;
  margin-right: 0px;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px;
  background: #fff;
  color: #6b6e80;
  position: relative;
  width: 90%;
}

.blog-comment .meta {
  font-size: 13px;
  color: #aaaaaa;
  padding-bottom: 8px;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #eee;
}

.blog-comment ul.comments ul {
  list-style-type: none;
  padding: 0;
  margin-left: 85px;
}

.blog-comment-form {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 40px;
}

.blog-comment h3,
.blog-comment-form h3 {
  margin-bottom: 40px;
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
}

.login-page {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.login-page img.logo {
  width: 60%;
  margin-bottom: 20px;
}

.login-page .centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #fff;
  padding: 0;
  border-radius: 5px;
}

.login-page .shadow {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}

.login-page .background {
  position: relative;
  height: 100vh;
}

.login-page .background:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: url("app/assets/images/background.jpg") no-repeat center;
  background-size: cover;
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.login-page .form-container {
  width: 100%;
  padding: 10px 25px;
  background-color: transparent;
  border: none;
}

.login-page .form-container .languages {
  display: inline-block;
  margin-right: auto;
}

.login-page .form-container .sign-in {
  margin-right: auto;
  float: right;
}

.login-page .lang {
  opacity: .5;
}

.login-page .lang.active {
  opacity: 1;
}

.login-page .footer {
  background-color: #fff;
  padding: 25px;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  z-index: 2;
}

.login-page .footer .logos {
  padding-left: 25px;
}

.login-page .footer .logos .logo {
  height: 65px;
  width: auto;
  margin: 0 10px;
}

.login-page .footer .socials {
  padding-right: 25px;
}

.login-page .footer .socials li {
  padding: 0 10px;
}

.login-page .footer .socials li a img {
  height: 30px;
  width: auto;
}

.sidebar {
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 999;
}

.sidebar li.list-group-item.disabled {
  opacity: .7;
}

.sidebar li.list-group-item.disabled.active {
  opacity: 1;
}

.widget .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.home {
  margin-top: 56px;
}

.home .list-group-item.child {
  padding-left: 3rem;
}

.home .list-group-item i.tree-icon {
  padding-right: 10px;
  width: 26px;
}

.home .page-heading .btn {
  height: 40px;
}

.admin {
  padding: 25px;
}

.admin .h2, .admin h2 {
  font-size: 1.5rem;
}

.admin .h3, .admin h3 {
  font-size: 1.75rem;
}

.admin .h5, .admin h5 {
  font-size: 1rem;
}

.admin .admin-heading .btn {
  height: 40px;
}

.admin a.disabled {
  cursor: not-allowed;
  opacity: .7;
  pointer-events: none;
}

.dash {
  padding: 25px;
}

.dash .h2, .dash h2 {
  font-size: 1.5rem;
}

.dash .h3, .dash h3 {
  font-size: 1.75rem;
}

.dash .h5, .dash h5 {
  font-size: 1rem;
}

.dash .showcase {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: #375f9b;
}

.dash .showcase .dash {
  padding: 10px 25px;
  margin: 20px;
  min-width: 300px;
  background-color: #778899;
  border-radius: 10px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.dash .showcase .dash:hover {
  background-color: #af628b;
}

.dash .showcase .dash .left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.dash .showcase .dash .left .icon {
  width: 64px;
  height: 64px;
  margin-right: 25px;
}

.dash .showcase .dash .left .icon.used {
  background-image: url("app/assets/images//dashboard/used.png");
  background-size: 100%;
}

.dash .showcase .dash .left .icon.notUsed {
  background-image: url("app/assets/images//dashboard/notUsed.png");
  background-size: 100%;
}

.dash .showcase .dash .left .icon.usedWith {
  background-image: url("app/assets/images//dashboard/usedWith.png");
  background-size: 100%;
}

.dash .showcase .dash .left .icon.ten {
  background-image: url("app/assets/images//dashboard/notUsed.png");
  background-size: 100%;
}

.dash .showcase .dash .left .icon.thirty {
  background-image: url("app/assets/images//dashboard/usedWith.png");
  background-size: 100%;
}

.dash .showcase .dash .left .icon.ninety {
  background-image: url("app/assets/images//dashboard/used.png");
  background-size: 100%;
}

.dash .showcase .dash .left .icon.total {
  background-image: url("app/assets/images//dashboard/total.png");
  background-size: 100%;
}

.dash .showcase .dash .right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column;
}

.dash .showcase .dash .right header h2 {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.95);
}

.dash .showcase .dash .right .body {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.dash .showcase .dash .right .body p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.stat {
  padding: 25px;
}

.stat .h2, .stat h2 {
  font-size: 1.5rem;
}

.stat .h3, .stat h3 {
  font-size: 1.75rem;
}

.stat .h5, .stat h5 {
  font-size: 1rem;
}

.stat p {
  margin-top: 0;
  margin-bottom: 0;
}

.stat .showcase {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: #375f9b;
}

.stat .showcase .stat {
  padding: 10px;
  min-width: 200px;
  border-radius: 10px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
}

.stat .showcase .stat fieldset.form-list {
  margin-bottom: 1rem;
  min-width: 200px;
}

.stat .showcase .stat fieldset.form-list label {
  color: white;
}

.stat .showcase .stat fieldset.form-group {
  margin-bottom: 1rem;
  min-width: 200px;
}

.stat .showcase .stat fieldset.form-group label {
  color: white;
}

.searchButton {
  padding: 40px;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none !important;
  border-bottom: 1px solid #bfc1c2;
}

.fiche {
  padding: 25px;
}

.fiche .h2, .fiche h2 {
  font-size: 1.5rem;
}

.fiche .h3, .fiche h3 {
  font-size: 1.75rem;
}

.fiche .h5, .fiche h5 {
  font-size: 1rem;
}

.fiche .admin-heading .btn {
  height: 40px;
}

.fiche a.disabled {
  cursor: not-allowed;
  opacity: .7;
  pointer-events: none;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .navbar .navbar-brand .toggleSidebar {
    display: inline-block;
  }
  .login-page .background {
    display: none;
  }
  .sidebar {
    position: fixed;
    width: 80%;
    left: -100%;
    top: 54px;
    bottom: 0;
    transition: all ease 500ms;
  }
  .sidebar.shown {
    left: 0;
    transition: all ease 500ms;
  }
}

/* Portrait phones and smaller */
